import 'core-js'
import 'regenerator-runtime/runtime'
import React from 'react'
import App from 'next/app'
import { CookiesProvider } from 'react-cookie'
import { ThemeProvider } from 'emotion-theming'
import * as Sentry from '@sentry/browser'

import { nodeEnv, sentryDsn, sentryRelease } from 'services/config'
import themeStyles from 'components/theme-styles'

// Sentry.init({
//   dsn: sentryDsn,
//   environment: nodeEnv,
//   release: sentryRelease
// })

export default class MyApp extends App {
  // componentDidCatch(err, extra) {
  //   Sentry.withScope(scope => {
  //     Object.keys(extra).forEach(key => {
  //       scope.setExtra(key, extra[key])
  //     })

  //     Sentry.captureException(err)
  //   })
  //   super.componentDidCatch(err, extra)
  // }

  render() {
    const { Component, pageProps } = this.props

    return (
      <CookiesProvider>
        <ThemeProvider theme={themeStyles}>
          <Component {...pageProps} />
        </ThemeProvider>
      </CookiesProvider>
    )
  }
}
