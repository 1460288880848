export default {
  colors: {
    jaguar: '#000000',
    gorilla: '#222222',
    sloth: '#BABABA',
    koala: '#777777',
    fox: '#7D7D7D',
    penguin: '#4a4a4a',
    silverfish: '#F7F7F7',
    rhino: '#E8E8E8',
    elephant: '#DFDFDF',
    lobster: '#D61E00',
    bug: '#D62002',
    cardinal: '#C1282D',
    rust: '#841A1D',
    leaf: '#417505',
    stone: '#363636',
    asphalt: '#181818',
    yang: '#fff',
    yin: '#000'
  },
  fonts: {
    roboto: '"Roboto Condensed", sans-serif',
    oswald: '"Oswald", sans-serif',
    lato: '"Lato", sans-serif'
  }
}
